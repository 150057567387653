<template>
    <div class="w-screen h-screen">
        <slot></slot>
    </div>
</template>

<script>
    import { StatusBar } from '@/native';

    export default {
        name: "FullscreenLayout",
        components: {
            StatusBar
        },
        created() {
            StatusBar.setBackgroundColor('#FFFFFF');
            StatusBar.setStyle('Light');
        }
    }
</script>